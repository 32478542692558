// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/tmp/3c405d26/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contato-js": () => import("/tmp/3c405d26/src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-index-js": () => import("/tmp/3c405d26/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-okcontato-js": () => import("/tmp/3c405d26/src/pages/okcontato.js" /* webpackChunkName: "component---src-pages-okcontato-js" */),
  "component---src-pages-produtos-js": () => import("/tmp/3c405d26/src/pages/produtos.js" /* webpackChunkName: "component---src-pages-produtos-js" */),
  "component---src-pages-revendas-js": () => import("/tmp/3c405d26/src/pages/revendas.js" /* webpackChunkName: "component---src-pages-revendas-js" */),
  "component---src-pages-servicos-js": () => import("/tmp/3c405d26/src/pages/servicos.js" /* webpackChunkName: "component---src-pages-servicos-js" */)
}

exports.data = () => import("/tmp/3c405d26/.cache/data.json")

